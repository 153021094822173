<template>
  <div>
    <b-container class="vh-100 layoutWrapper">
      <b-row class="border border-dark rounded layoutNavWrapper">
        <AppLayoutNav />
      </b-row>
      <b-row class="border border-dark rounded layoutMainWrapper">
        <slot />
      </b-row>
    </b-container>
    
  </div>
</template>

<script>
import AppLayoutNav from '@/layouts/AppLayoutNav'
export default {
  name: "AppLayoutGraph",
    components: {
    AppLayoutNav
  }
}
</script>

<style>
.layoutWrapper {
  position: relative;
  height: 95%;
  max-height: 850px;
  max-width: var(--appMaxWidth);
  padding: 0;
  padding-top: 10px;
}
.layoutNavWrapper {
  background-color: var(--tileColor);
  padding-bottom: 3px;
  margin: 0;
}
.layoutMainWrapper {
  height: 95%;
  background-color: var(--tileColor);
  justify-content: center;
  margin: 0;
}

</style>